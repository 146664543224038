<template>
  <div class="contianer">
    <h4>Lead Service Line Inventory</h4>
    <b-alert show variant="info" class="mt-3">
      Lead Service Line Inventory information and <b>templates</b> can be found on the <b-link href="https://msdh.ms.gov/page/30,25291,76,979.html" target="_blank">MSDH Website</b-link>.
    </b-alert>
    <b-card title="Upload Inventory File" class="mt-3">
    <div v-if="signed_in && pws_id_signed_in">
      <b-alert show variant="info">
        Uploading for <b>{{pws_label}}</b>.
        <b-link to="/change-water-system">Change PWS</b-link>
      </b-alert>
      <b-form-group label="Select a file (xlsx) to upload." label-for="lsli-upload">
        <b-form-file id="lsli-upload"  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" v-model="lsli_upload_file"></b-form-file>
      </b-form-group>
      <b-button :disabled="!file_ok || uploading" @click="onUploadInventoryFile" variant="primary">{{ uploading ? 'Uploading...' : 'Upload'   }}</b-button>
      <div class="mt-3">
      <b-alert fade dismissible variant="success" v-model="upload_success_alert">
        File <u>{{lsli_upload_file_name}}</u> uploaded successfully. Thank you!
      </b-alert>
      <b-alert fade dismissible variant="danger" v-model="upload_failure_alert">
        File upload failed.
      </b-alert>
      </div>
    </div>
    <div v-else class="mt-5">
      <b-alert show variant="info">
        You must be <b-link to="signin">signed in </b-link>to <b>upload Lead Service Line Inventory files</b>.
      </b-alert>
    </div>
    </b-card>
    <b-card title="Uploaded Files" class="mt-3">
      <ul v-if="lsli_filenames.length > 0">
        <li v-for="filename in lsli_filenames" :key="filename">
          {{ filename[0] }} - {{ filename[1] }}
        </li>
      </ul>
      <b-alert v-else show variant="warning">
        No files uploaded.
      </b-alert>
    </b-card>
    <b-card title="Lead Service Line Inventory Assistance" class="mt-3">
      <b-form-group class="mt-4" label="Do you require assistance with your Lead Service Line Inventory?">
        <b-form-radio-group buttons @change="onAssistanceChanaged" v-model="need_assistance" :options="need_assistance_options" button-variant="outline-primary"></b-form-radio-group>
      </b-form-group>
      <b-alert :show="need_assistance_changed && !submit_error" variant="success">
        Thank you.  Your response was recorded.
        <span v-if="need_assistance == 'yes'">We will contact you shortly.</span>
      </b-alert>
      <b-alert :show="need_assistance_changed && submit_error" variant="error">
        There was an error recording your response.
      </b-alert>
    </b-card>
  </div>
</template>

<script>

export default {
  name: "LeadInventory",
  data() {
    return {
      submit_error: false,
      need_assistance: 'no response',
      need_assistance_changed: false,
      need_assistance_options: [
        { text: 'No Response', value: 'no response' },
        { text: 'No', value: 'no' },
        { text: 'Yes', value: 'yes' }
      ],
      lsli_filenames: [],
      lsli_upload_file: null,
      lsli_upload_file_name: '',
      uploading: false,
      upload_success_alert: false,
      upload_failure_alert: false,
    }
  },
  methods: {
    async getAssistanceValue() {
      const url = process.env.VUE_APP_API_URI + '/pws-polls/lsli-assistance/' + this.pws_id_signed_in
      try {
        const res = await this.axios.get(url)
        console.log(res)
        if (res.data && res.data.poll_submit_data) {
          this.need_assistance = res.data.poll_submit_data.poll_data.need_assistance
        }
      } catch (error) {
        console.error(error)
      }
    },
    async onAssistanceChanaged() {
      this.submit_error = false
      this.need_assistance_changed = true
      console.log(this.need_assistance)
      const url = process.env.VUE_APP_API_URI + '/pws-polls/lsli-assistance/' + this.pws_id_signed_in
      console.log('url', url)
      const data = { "poll_data": {need_assistance: this.need_assistance} }
      try {
        const res = await this.axios.post(url, data)
        console.log(res)
      } catch (error) {
        this.submit_error = true
        console.error(error)
      }
    },
    async getFileList() {
      const url = process.env.VUE_APP_API_URI + '/lsli-files' + '?pws_id=' + this.pws_id_signed_in
      try {
        const res = await this.axios.get(url)
        this.lsli_filenames = []
        for (const filename of res.data.filenames) {
          filename[1] = new Date(filename[1]).toLocaleString()
          this.lsli_filenames.push(filename)
        }
        console.log(res)
      } catch (error) {
        console.error(error)
      }
    },
    onUploadInventoryFile() {
      const form_data = new FormData()
      this.lsli_upload_file_name = this.lsli_upload_file.name
      form_data.append('lsli_file', this.lsli_upload_file)
      form_data.append('pws_id', this.pws_id_signed_in)
      this.uploading = true
      this.axios.post(process.env.VUE_APP_API_URI + '/lsli-file', form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        this.uploading = false
        console.log(res)
        if (res.data && res.data.msg == 'OK') {
          this.upload_success_alert = true
          this.getFileList()
          this.lsli_upload_file = null
        } else {
          this.upload_failure_alert = true
        }
      }).catch((error) => {
        this.uploading = false
        console.error(error)
        this.upload_failure_alert = true
      })
    }
  },
  mounted() {
    this.getAssistanceValue()
    this.getFileList()
  },
  computed: {
    pws_label() {
      return `${this.$store.state.pws_id} -${this.$store.state.pws.pws_name}`
    },
    pws_id_signed_in() {
      return this.$store.state.pws_id
    },
    signed_in() {
      return this.$store.state.signed_in
    },
    file_ok() {
      return this.lsli_upload_file && this.lsli_upload_file.name.endsWith('.xlsx')
    }
  }
}
</script>
